import { useEffect, useMemo, useState } from 'react'
import { settingsService } from '../../../../../../infrastructure/services/settingsService'
import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  RowDragEvent,
} from 'ag-grid-community'
import { checkPermissions } from '../../../../../../infrastructure/utils/permissions'
import { MenuItemDef } from 'ag-grid-enterprise'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../infrastructure/store'
import { UserRoles } from '../../../../../../infrastructure/interfaces/users'
import storageService from '../../../../../../infrastructure/services/storageService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { folderColumns } from '../../../../../../infrastructure/constant/columns/folderColumns'
import { useDispatch } from 'react-redux'
import { setMenuSignal } from '../../../../../../infrastructure/store/sidebar/sidebarSlice'

const useFolderList = ({
  companyId,
  folderId,
}: {
  companyId: number
  folderId: number
}) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [rowData, setRowData] = useState<any[]>([])
  const [reportsSelected, setReportsSelected] = useState<any>(null)
  const [deleteModal, setDeleteModal] = useState({ open: false, id: 0 })
  const [showAddReportModal, setShowAddReportModal] = useState({
    open: false,
    type: '',
    title: '',
  })
  const [reportsAvailable, setReportsAvailable] = useState<any[]>([])
  const [cmsAvailable, setCmsAvailable] = useState<any[]>([])
  const [labelReport, setLabelReport] = useState<string>('')
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user: any = useSelector((state: RootState) => state.auth)
  const menu = useSelector((state: RootState) => state.sidebar.menu)

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    getFolderListById()
  }

  const getFolderListById = async () => {
    settingsService.getMenuItems({ id: companyId.toString() }).then((res) => {
      const data = res?.data?.find((item: any) => item.id === folderId)
      setRowData(data?.submenu || [])
    })
  }

  const focusAndEditNewFolder = (gridApi: any, id: any) => {
    setTimeout(() => {
      const rowIndex = gridApi.getRowNode(id)?.rowIndex
      if (rowIndex !== undefined) {
        gridApi.ensureIndexVisible(rowIndex, 'top')
        gridApi.setFocusedCell(rowIndex, 'label')
        gridApi.startEditingCell({
          rowIndex,
          colKey: 'label',
        })
      }
    }, 0)
  }

  const getContextMenu = (row: GetContextMenuItemsParams): MenuItemDef[] => {
    if (!row.value) return []
    const items: MenuItemDef[] = []

    let currentUser = user?.user || storageService.getUser()

    if (
      checkPermissions(['users.*', 'users.edit'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: t('titles.Editar'),
        action: () => {
          // lo que haremos es hacer focus en el input para editar el nombre del reporte
          focusAndEditNewFolder(gridApi, row?.node?.id)
        },
        cssClasses: ['tw-cursor-pointer'],
      })
    }
    if (
      checkPermissions(['users.*', 'users.delete'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: t('titles.Eliminar'),
        action: () => {
          setDeleteModal({
            open: true,
            id: row?.node?.data.id,
          })
        },
        cssClasses: ['tw-text-alert', 'tw-cursor-pointer'],
      })
    }
    return items
  }

  const onDeleteMenuItem = (id: number) => {
    settingsService.deleteMenuItem(id).then((res) => {
      if (res.status === 200) {
        dispatch(setMenuSignal(true))
        setDeleteModal({ open: false, id: 0 })
        getFolderListById()
      }
    })
  }
  useEffect(() => {
    const handleAddCms = (event: any) => {
      if (event.detail.open) {
        getCmsList()
        setShowAddReportModal({
          open: true,
          type: 'page',
          title: 'Añadir CMS',
        })
      }
    }

    window.addEventListener('addCms', handleAddCms)

    return () => {
      window.removeEventListener('addCms', handleAddCms)
    }
  }, [])

  useEffect(() => {
    const handleAddReport = (event: any) => {
      if (event.detail.open) {
        getReportsList()
        setShowAddReportModal({
          open: true,
          type: 'report',
          title: 'Añadir Informe',
        })
      }
    }

    window.addEventListener('addReport', handleAddReport)

    return () => {
      window.removeEventListener('addReport', handleAddReport)
    }
  }, [])

  const getReportsList = () => {
    settingsService
      .getReports({ page: 1, per_page: 500 })
      .then((response) => {
        const { items } = response.data
        const reports = items.filter(
          (report: any) => report.company.id === companyId
        )
        setReportsAvailable(reports)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCmsList = () => {
    settingsService
      .getPages({ page: 1, per_page: 500 })
      .then((response) => {
        const { items } = response.data
        // filtrar los reportes por su company.id
        const cms = items.filter((cms: any) => cms.company.id === companyId)
        setCmsAvailable(cms)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleCloseModal = () => {
    setShowAddReportModal({
      open: false,
      type: '',
      title: '',
    })
  }

  const handleSelect = ({ selected, type }: any) => {
    const id = selected.value
    let options = type === 'report' ? reportsAvailable : cmsAvailable
    const report = options.find((report) => report.id === id)
    report.type = type
    setReportsSelected(report)
  }

  const reportsAvaillables = useMemo(() => {
    // Filtrar los reportes que ya están en rowData
    const existingReports = rowData?.map((item) => item?.report?.id)
    return reportsAvailable
      .filter((report) => !existingReports.includes(report.id))
      .map((report) => {
        return {
          value: report.id,
          label: report.name,
        }
      })
  }, [reportsAvailable, rowData])

  const cmsAvaillables = useMemo(() => {
    // Filtrar los CMS que ya están en rowData
    const existingCms = rowData.map((item) => item?.page?.id)
    return cmsAvailable
      .filter((cms) => !existingCms.includes(cms.id))
      .map((cms) => {
        return {
          value: cms.id,
          label: cms.title,
        }
      })
  }, [cmsAvailable, rowData])

  const handleNameChangeReport = (event: any) => {
    const name = event.target.value
    setLabelReport(name)
  }

  const onAddReport = ({ type }: any) => {
    const newItemMenu = {
      label: labelReport,
      type: type,
      company_id: companyId,
      parent_menu_id: folderId,
      icon: reportsSelected?.icon,
      entity_id: reportsSelected?.id,
      slug: reportsSelected?.slug,
      weight: rowData.length + 1,
    }
    if (!reportsSelected || !labelReport) return
    settingsService.postMenuItems(newItemMenu).then((res) => {
      if (res.status === 200) {
        toast.success(`${t('messages.menu-añadido')}`, {
          position: 'top-center',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          autoClose: 5000,
        })
        getFolderListById()
        handleCloseModal()
        setReportsSelected(null)
        setLabelReport('')
        dispatch(setMenuSignal(true))
      }
    })
  }

  const onRowDragEnd = (event: RowDragEvent) => {
    const { overIndex, nodes } = event
    const movedNode = nodes[0]
    const updatedData = rowData.slice()
    updatedData.splice(updatedData.indexOf(movedNode.data), 1)
    updatedData.splice(overIndex, 0, movedNode.data)

    // Construir los datos reordenados solo con los submenús
    const reorderedSubMenu = updatedData.map((item, index) => ({
      id: item.id,
      weight: index,
      parent_menu_id: folderId,
    }))
    // Encontrar el menú padre y actualizar su submenu
    const updatedMenu = menu?.map((item) => {
      if (item.id === folderId) {
        return {
          id: item.id,
          weight: item.weight,
          submenu: reorderedSubMenu,
        }
      }
      return {
        ...item,
        submenu: item.submenu.map((sub) => {
          return {
            ...sub,
            parent_menu_id: item.id,
          }
        }),
      }
    })

    // Enviar el menú completo con el submenu reordenado
    settingsService.reorderMenuItems(updatedMenu).then((response) => {
      if (response.status === 200) {
        getFolderListById()
        setRowData(updatedData)
        dispatch(setMenuSignal(true))
      }
    })
  }

  const onCellEditingStopped = (event: any) => {
    const { data, oldValue, newValue } = event

    if (newValue === oldValue) {
      return
    }
    const newData = {
      id: data.id,
      company_id: data.company_id,
      entity_id: data.report?.id || data.page?.id,
      parent_menu_id: folderId,
      label: data.label,
      icon: data.icon,
      slug: data.slug,
      type: data.type,
      weight: data.weight,
    }
    settingsService
      .putMenuItems(newData.id, newData)
      .then(() => {
        toast.success(`${t('messages.menu-editado')}`, {
          position: 'top-center',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          autoClose: 5000,
        })
        dispatch(setMenuSignal(true))
      })
      .catch(() => {
        toast.error(`${t('messages.menu-error')}`, {
          position: 'top-center',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          autoClose: 5000,
        })
      })
  }

  return {
    rowData,
    columns: folderColumns,
    onGridReady,
    gridApi,
    deleteModal,
    setDeleteModal,
    getContextMenu,
    onDeleteMenuItem,
    showAddReportModal,
    handleCloseModal,
    handleSelect,
    selectedOption: reportsSelected,
    reportsAvailable: reportsAvaillables,
    cmsAvailable: cmsAvaillables,
    handleNameChangeReport,
    onAddReport,
    onRowDragEnd,
    onCellEditingStopped,
  }
}

export default useFolderList
