import { Embed, models, Report, service } from 'powerbi-client'
import { EmbedType, PowerBIEmbed } from 'powerbi-client-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import Paragraphs from '../../components/commons/Paragraphs/Paragraphs'
import PowerBiFilterInput from '../../components/commons/PowerBi/PowerBiFilterInput'
import MenuList from '../../components/dashboard/MenuList'
import { ICMS } from '../../infrastructure/interfaces/settings'
import { biService } from '../../infrastructure/services/biService'
import { settingsService } from '../../infrastructure/services/settingsService'
import { RootState } from '../../infrastructure/store'
import RoutePlanner from '../RoutePlanner/RoutePlanner'
import { Container } from './styles'

declare global {
  interface Window {
    report: any
  }
}

const Dashboard = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const params = useParams()
  const { selectedMenu } = useSelector((state: RootState) => state.sidebar)

  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState<any>()
  const [reportId, setReportId] = useState<string>()
  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: EmbedType.Report,
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
      id: undefined,
      pageName: undefined,
    })
  const [pageData, setPageData] = useState<ICMS>()
  const [filterInput, setFilterInput] = useState<string>('')
  const [menuReport, setMenuReport] = useState<any>()
  const [powerBiDefaultFilters, setPowerBiDefaultFilters] = useState<any>([])

  useEffect(() => {
    if (selectedMenu) {
      setLoading(true)
      setPageData(undefined)
      setReport(null)
      if (selectedMenu.slug !== '/home' && 'id' in selectedMenu) {
        if ('report' in selectedMenu) {
          getReportBiData(selectedMenu.slug)
          // change url with reload
          window.history.pushState(
            {},
            '',
            `${window.location.origin}/reports/${selectedMenu.slug}`
          )
          setPageData(undefined)
        }
        if ('page' in selectedMenu) {
          getPageData(selectedMenu.page?.slug as string)
          // change url with reload
          window.history.pushState(
            {},
            '',
            `${window.location.origin}/pages/${selectedMenu.slug}`
          )
          setReport(undefined)
        }
      }

      if (location.pathname === '/home') {
        setPageData(undefined)
        setReport(undefined)
        setLoading(false)
      }
    }
  }, [selectedMenu])

  // unmount component
  useEffect(() => {
    return () => {
      localStorage.removeItem('powerBiFilters')
      localStorage.removeItem('powerBiPageName')
      setPowerBiDefaultFilters([])
      setReport(undefined)
      setPageData(undefined)
      setReportConfig({
        type: EmbedType.Report,
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
        id: undefined,
        pageName: undefined,
      })
    }
  }, [])

  let stop = false
  useEffect(() => {
    if (params && params.slug) {
      if (!stop) {
        if (location.pathname.includes('reports') && !stop) {
          getReportBiData(params.slug)
        }
        if (location.pathname.includes('pages') && !stop) {
          getPageData(params.slug)
        }
      }
    }
  }, [params.slug])

  let callingReport = false
  const getReportBiData = async (slugOrId: string) => {
    if (!callingReport) {
      callingReport = true
      try {
        const res = await biService.getReportBITokens(slugOrId)
        if (res) {
          if (res.status === 200) {
            const { data } = res

            let filters: any = null
            if (
              !Array.isArray(data.filter) &&
              typeof data.filter === 'object'
            ) {
              filters = constructFilters(data.filter)
              localStorage.removeItem('powerBiFilters')
            }

            if (filters) {
              if (filters.target.column !== '') {
                localStorage.setItem('powerBiFilters', JSON.stringify(filters))
              } else {
                localStorage.removeItem('powerBiFilters')
              }
            } else {
              localStorage.removeItem('powerBiFilters')
            }

            setReportConfig({
              ...reportConfig,
              embedUrl: data.embed_url,
              tokenType: models.TokenType.Embed,
              accessToken: data.embed_token,
              id: data.report_id,
              pageName: data.report_section,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
              },
            })

            localStorage.setItem('powerBiPageName', data.report_section)

            setReportId(data.report_id)
            setMenuReport(res.data)
            setLoading(false)
          } else {
            setLoading(false)
            setReport(undefined)
          }
        }
      } catch (e) {
        setLoading(false)
        setReport(undefined)
      }
      callingReport = false
      stop = true
    }
  }

  const constructFilters = (filters: any) => {
    if (
      filters &&
      typeof filters === 'object' &&
      Object.keys(filters).length > 0
    ) {
      Object.keys(filters).forEach((key) => {
        if (filters[key] === null) {
          delete filters[key]
        }
      })
    } else filters = false

    const userFilterValue = localStorage.getItem('powerBiFilter')

    if (
      filters &&
      filters.table &&
      filters.table !== '' &&
      filters.column &&
      filters.column !== '' &&
      (filters.values || userFilterValue)
    ) {
      return {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: filters.table,
          column: filters.column,
        },
        operator: 'In',
        values: userFilterValue
          ? [userFilterValue.toUpperCase()]
          : Array.isArray(filters.values) && filters.values.length > 0
          ? filters.values
          : null,
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      }
    } else if (userFilterValue && filters) {
      return {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: filters.table,
          column: filters.column,
        },
        operator: 'In',
        values: [userFilterValue.toUpperCase()],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      }
    } else if (filters) {
      return {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: filters.table,
          column: filters.column,
        },
        operator: 'In',
        values:
          Array.isArray(filters.value) && filters.value > 0
            ? filters.value
            : null,
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      }
    } else return undefined
  }

  const updateFilters = async (filters = []) => {
    if (!window.report) return
    const userFilterValue = localStorage.getItem('powerBiFilter') || null
    const reportFilters = localStorage.getItem('powerBiFilters')
      ? JSON.parse(localStorage.getItem('powerBiFilters') || '')
      : null
    const pageName = localStorage.getItem('powerBiPageName')

    if (reportFilters && typeof userFilterValue === 'string') {
      reportFilters.values = [userFilterValue.toUpperCase()]
    }

    let arrayFilters: any[] = []
    if (filters.length > 0) {
      arrayFilters.push(...filters)
    }

    if (arrayFilters.length > 0 && reportFilters) {
      arrayFilters = arrayFilters.filter((f: any) => {
        return (
          f.target.table !== reportFilters.target.table &&
          f.target.column !== reportFilters.target.column
        )
      })
    }

    if (reportFilters) {
      arrayFilters.push(reportFilters)
    }

    //@ts-ignore
    try {
      let page = await window.report.getPages()
      page = page || []

      if (page.length > 0 && pageName) {
        page = page.find((p: any) => p.name === pageName)

        if (arrayFilters.length > 0 && page) {
          await page.updateFilters(
            models.FiltersOperations.ReplaceAll,
            arrayFilters
          )

          console.warn('FILTROS AHORA APLICADOS', await page.getFilters())
          setPowerBiDefaultFilters(arrayFilters)
        } else if (page && arrayFilters.length === 0) {
          await page.updateFilters(models.FiltersOperations.RemoveAll)
          setPowerBiDefaultFilters([])
          console.warn('FILTROS RESETEADOS')
        } else {
          // set filters to report
          setPowerBiDefaultFilters(arrayFilters)
          await window.report.updateFilters(
            models.FiltersOperations.ReplaceAll,
            arrayFilters
          )
        }
      } else {
        await window.report.updateFilters(
          models.FiltersOperations.ReplaceAll,
          arrayFilters
        )

        console.warn('FILTROS AHORA APLICADOS', await page.getFilters())
        setPowerBiDefaultFilters(arrayFilters)
      }
    } catch (error) {
      console.error('Error occurred while getting pages:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    updateFilters()
  }, [filterInput])

  let callingPage = false
  const getPageData = async (slugOrId: string) => {
    setLoading(true)
    if (!callingPage) {
      callingPage = true
      const res = await settingsService.getPage(slugOrId)
      if (res) {
        if (res.status === 200) {
          const { data } = res
          let orderedWigets = data.widgets?.sort(
            (a: any, b: any) => a.position - b.position
          )
          setPageData({ ...data, widgets: orderedWigets })
          setLoading(false)

          if (data.widgets.length > 0) {
            //check if widget has report
            const report = data.widgets.find((w: any) => w.report)
            if (report) {
              getReportBiData(report.report.slug)
            }
          }
        } else {
          setLoading(false)
          setPageData(undefined)
        }
      }

      setTimeout(() => {
        callingPage = false
        stop = true
      }, 1000)
    }
  }

  useEffect(() => {
    if (reportConfig || pageData) {
      setLoading(false)
    }
  }, [reportConfig, pageData])

  // clean up side effects
  useEffect(() => {
    return () => {
      localStorage.removeItem('powerBiFilters')
      localStorage.removeItem('powerBiPageName')
      setPowerBiDefaultFilters([])
      setLoading(false)
      setReport(undefined)
    }
  }, [])

  const eventHandlersMap = new Map([
    [
      'loaded',
      async function () {
        console.info('Report has loaded')
        //@ts-ignore
        let page = await window.report.getPages()
        if (page && page.length > 0) {
          const pageName = localStorage.getItem('powerBiPageName')
          page = page.find((p: any) => p.name === pageName)
          let filters = await page?.getFilters()
          console.warn('FILTROS APLICADOS POR DEFECTO', filters)
          updateFilters(filters)
        } else {
          updateFilters()
        }
      },
    ],
    [
      'pageChanged',
      async function (event: any) {
        const { detail } = event
        let filter = (await detail?.newPage?.getFilters()) || []
        const pageName = localStorage.getItem('powerBiPageName')
        let page = await window.report.getPages()
        page = page.find((p: any) => p.name === pageName)
        if (page) {
          const lastPageFilters = page ? await page.getFilters() : []
          console.warn('FILTROS APLICADOS A LA PÁGINA', filter)
          await page.updateFilters(models.FiltersOperations.ReplaceAll, filter)
          updateFilters(lastPageFilters)
          localStorage.setItem('powerBiPageName', page.name)
        }
      },
    ],
    [
      'rendered',
      async function (e: any) {
        // rendered
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        //Report has error
      },
    ],
  ])

  const downloadReportPDF = async () => {
    const url = `https://api.powerbi.com/v1.0/myorg/reports/${reportConfig.id}/ExportTo`
    const body = {
      format: 'pdf',
      landscape: true,
      pagesLayout: 'SinglePage',
      powerBIReportConfiguration: {
        reportLevelFilters: powerBiDefaultFilters,
      },
    }

    // TODO: waiting for the premium capacity to be able to download the report
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    const blob = await res.blob()
    const urlBlob = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlBlob
    link.setAttribute('download', `${reportConfig.pageName || 'report'}.pdf`)
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }

  return (
    <Container>
      {(selectedMenu?.slug !== '/home' && !loading && report && !pageData) ||
      (location.pathname.includes('reports') && reportId) ? (
        <div className="tw-flex tw-flex-col xl:tw-min-w-[1200px] tw-h-full tw-w-full">
          {menuReport &&
            !Array.isArray(menuReport.filter) &&
            menuReport.filter.table !== '' && (
              <PowerBiFilterInput
                onFilterChange={(filterInput: string) =>
                  setFilterInput(filterInput)
                }
                downloadReportPDF={downloadReportPDF}
                report={menuReport}
              />
            )}
          <PowerBIEmbed
            embedConfig={reportConfig}
            cssClassName={
              'report-style-class tw-w-full tw-h-full tw-overflow-auto'
            }
            getEmbeddedComponent={(embedObject: Embed) => {
              // @ts-ignore
              window.report = embedObject
              setReport(embedObject as Report)
            }}
            eventHandlers={eventHandlersMap}
          />
        </div>
      ) : (!loading &&
          selectedMenu &&
          selectedMenu.slug !== '/home' &&
          pageData &&
          pageData.widgets) ||
        (!loading &&
          location.pathname.includes('pages') &&
          pageData &&
          pageData.widgets) ? (
        <div className="tw-w-full tw-h-full tw-overflow-auto">
          {pageData.widgets.map((widget: any, index: number) => {
            if (widget.type === 'bi' && widget.report) {
              return (
                <div
                  className="tw-my-8 tw-h-full tw-border-b-4 tw-pb-2"
                  key={widget.id}
                >
                  <PowerBIEmbed
                    key={widget.id}
                    embedConfig={reportConfig}
                    cssClassName={'report-style-class tw-w-full tw-h-full'}
                    getEmbeddedComponent={(embedObject: Embed) => {
                      // @ts-ignore
                      window.report = embedObject
                      setReport(embedObject as Report)
                    }}
                    eventHandlers={eventHandlersMap}
                  />
                </div>
              )
            }
            if (widget.type === 'html') {
              const parser = new DOMParser()
              const parsedHtml = parser.parseFromString(
                widget.content,
                'text/html'
              )
              const elementsWithClasses = parsedHtml.querySelectorAll(
                '[class*="route-planner"]'
              )

              if (elementsWithClasses.length > 0) {
                // render components based on found classes in HTML content
                return Array.from(elementsWithClasses).map((element, index) => {
                  const className = element.className // Get the found class name
                  // logic to map classes with corresponding components to render
                  switch (className) {
                    case 'route-planner':
                      return <RoutePlanner key={`${widget.id}-${index}`} />
                    default:
                      return null
                  }
                })
              }

              return (
                <div
                  key={widget.id}
                  className="tw-p-8"
                  dangerouslySetInnerHTML={{ __html: widget.content }}
                />
              )
            }
          })}
        </div>
      ) : (
        <div className="tw-w-full tw-h-full tw-px-8 tw-py-8">
          {loading ? (
            <Paragraphs>{t('messages.Cargando')}</Paragraphs>
          ) : (
            pageData === undefined && <MenuList />
          )}
        </div>
      )}
    </Container>
  )
}

export default Dashboard
